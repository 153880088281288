import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Grid from '../../../components/molecules/Grid';
import Typography from '../../../components/atoms/Typography';
import Picture from '../../../components/atoms/Picture';
import { get } from 'lodash';
import useStyles from './style';
import { COMPONENT_NAME } from './locators';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SponsorGrid = props => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isFieldsEmpty = checkEmptyObject(props.fields);
  const { title, items } = props.fields;

  let itemList = items;
  let popped = null;
  if (isMobile && items.length % 2 !== 0) {
    popped = itemList.pop();
  }

  const classes = useStyles(props);

  return (
    isFieldsEmpty ? <></> :
      <div
        className={classes.root}
        data-locator={COMPONENT_NAME}
      >
        <Grid className={classes.sponsorGridWrapper}>
          <Typography variant="h5Bold" component="h3">
            <Text field={title} />
          </Typography>

          <div className={classes.sponsorList}>
            {itemList.map(item => {
              return (
                <div key={item.id} className={classes.sponsorItem}>
                  <Link to={get(item, 'fields.imageCTA.value.href', '')} target={get(item, 'fields.imageCTA.value.linktype', '') !== "internal" ? "_blank" : "_self"} >
                    <Picture
                      media={{
                        image: get(item, 'fields.image', ''),
                        disableLazyLoad: true,
                        fallbackBackgroundNeeded: false,
                      }}
                      className={'display-picture'}
                      isParalax={false}
                    />
                  </Link>
                </div>
              )
            })}
          </div>
          {popped &&
            <div className={classes.poppedItemWrapper}>
              <div className={classes.sponsorItem}>
                <Link to={get(popped, 'fields.imageCTA.value.href', '')} target={get(popped, 'fields.imageCTA.value.linktype', '') !== "internal" ? "_blank" : "_self"} >
                  <Picture
                    media={{
                      image: get(popped, 'fields.image', ''),
                      disableLazyLoad: true,
                      fallbackBackgroundNeeded: false,
                    }}
                    className={'display-picture'}
                    isParalax={false}
                  />
                </Link>
              </div>
            </div>
          }
        </Grid>
      </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(SponsorGrid))
);
